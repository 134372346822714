.topbar {
    z-index: 200;
    position: relative;
    background: $color-white;
    width: 100%;
    position: fixed;
    top: 0;
    box-shadow: $blocks-shadow;
    @media (min-width: 0px) and (max-width:800px) {
        height: 80px;
    }
    @media (min-width: 801px)  {
        height: 130px;
    }
    &__menu {
        left: 0;
        right: 0;
        margin:0 auto;
        background: $color-white;
        position: relative;
        &::before {
            position: fixed;
            top: 0;
            right: 0;
            content: '';
            display: block;
            width: 100%;
            @include breakpoint(small) {
                content: url(../svg/mobile-icon.svg);
                margin: 22px -330px;
            }
            @include breakpoint(medium) {
                content: '';
                margin: 13px -380px;
            }
        }
        #search_toggle {
            position:absolute;
            right:20px;
            top:24px;

            &:before {
                width:20px;
                height:20px;
                background:url('../svg/blue-mob-search-icon.svg');
                background-size:contain;
                content:'';
                display:block;
            }
            span {
                display:none;
            }
        }
    }

    //????
    .zoeken-box input {
        height: 50px;
        padding-left: 11px;
        background: white;
        width: 190px;
        float: right;
        margin: 0 38px;
        border: 1px solid $color-blue;
        box-shadow: none !important;
        color: $color-blue;
    }
    //????

    //SEARCHFORM
    &__search {
        @media (min-width: 0px) and (max-width: 800px) {
            #searchall-searchform {
                display:none;
            }
        }
        &.active {
            position:fixed;
            background:#fff;
            right:0;
            left:0;
            width:100%;
            height:80px;
            display:block;
            z-index: 5000;
            #searchall-searchform {
                width:80%;
                margin-left:0.9375rem;
                display:block;
                .zoeken-icon {
                    background:url("../svg/zoeken-icon.svg") no-repeat center center $color-blue;
                    right: 0px;
                    &:hover {
                        background:url("../svg/search-icon-blue.svg") no-repeat center center #fff;
                    }
                }
                .allsearch-input {
                    margin-top: 16px;
                }
            }
            #close_toggle {
                display:block;
                position:absolute;
                right:1.2rem;
                top:2rem;
                &:before {
                    width:16px;
                    height:16px;
                    background:url('../img/close.svg');
                    background-size:contain;
                    content:'';
                    display:block;
                }
                span {
                    display:none;
                }
            }
        }
    }

    #close_toggle, #search_toggle {
        display:none;
    }
    @media all and (max-width: 800px) and (min-width:0px) {
        #search_toggle {
            display:block;
        }
        #close_toggle.active {
            display:block;
        }
    }

    #searchall-searchform {
        position:relative;
        label {
            display: none;
        }
    }


    //Search Icon at topbar
    .zoeken-icon {
        background: url('../img/search-icon.svg') no-repeat center $color-blue;
        text-indent: -220px;
        width: 50px;
        height: 50px;
        border: none;
        margin: 0;
        position: absolute;
        top:0;
        right:0;
        &:hover {
            background: url('../img/search-icon-blue.svg') no-repeat center #fff;
            border:1px solid $color-blue;
        }
        @include breakpoint(small only) {
            background-color: $color-white;
            background-image:  url(../svg/blue-mob-search-icon.svg);
            right: 5px;
        }
    }
    .allsearch-input {
        height: 50px;
        margin-top: 38px;
        border: 1px solid $color-blue;
        box-shadow: none !important;
        &::-moz-placeholder {
            color: $color-blue;
            opacity:1;
            font-size:rem-calc(20);
        }
        /* @include breakpoint(small only) {
            display: none;
        } */
    }


    //Search input topbar
    .zoeken-box {
        position: absolute;
        background: $color-blue;
        z-index: 999;
        width: 54px;
        height: 50px;
        right: 17px;
        top: 25px;
        display: none;
        @include breakpoint(medium) {
            display: block;
        }
    }

    &__logo {
        z-index: 5;
        img {
            margin-top:20px;
            //height: 90px;
            @extend %transition02sec;
            @include breakpoint (medium) {
                height: 78px;

                &:hover {
                    height:80px;
                }
            }
            @include breakpoint  (small) {
                height: 48px;
            }
        }
    }
    //MAINMENU
    &__main-nav {
        font-size: rem-calc(24);
        text-align:center;
        #topbar {
            margin: 38px auto 0;
            width:auto;
            > li > a {
                color:$color-blue;
                font-weight:700;
                //@extend %transition03sec;
                &:hover {
                    color: #000;
                }
            }
            > li.active > a {
                background:none;
                color:#000;
            }
            a {
                font-weight:700;
            }
        }
        .title-bar {
            display: none;
            @include breakpoint (small) {
                display: block;
                background: none;
                position: relative;
            }
            .hamburger-icon {
                @include hamburger($color-blue, $dark-gray, 20px, 16px, 2px, 3);
                max-width:20px;
                background: none;
                border: none;
                @include breakpoint (medium) {
                    display: none;
                }
            }
        }
        .nav-child {
            display:none;
        }
        .dropdown {
            margin: 0 auto;
            @include breakpoint (medium) {
                display: none;
            }
            > li {
                position: relative;
                padding: 0;
                height: 90px;
                &.is-active {
                    &::before {
                        position: fixed;
                        top: 90px;
                        right: 0;
                        content: '';
                        display: block;
                        width: 100%;
                        height: 52px;
                        @include breakpoint (medium) {
                            display: none;
                        }
                    }
                    &::after {
                        @include css-triangle(20px, $color-light-gray, up);
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        content: '';
                        display: block;
                        transform: translateX(-50%);
                    }
                }

                .nav-header {
                    position: relative;
                    cursor: default;
                    font-size: 20px;
                    @include breakpoint (large) {
                        font-size: 19px;
                    }
                    @include breakpoint (small) {
                        font-size: 15px;
                        padding: 0.7rem 0.6rem;
                    }
                }
            }
        }

        .is-dropdown-submenu {
            @include flex-grid-row(null, $grid-row-width, null, true, $grid-column-gutter);
            padding: 7px 0 12px;
            display: none;
            left: 0 !important;
            right: 0 !important;
            box-shadow: $blocks-shadow;
            &::before{
                content: '';
                display: block;
                width: 100%;
                background: #000;
            }
            &.js-dropdown-active {
                display: block;
            }
            li {
                font-size:rem-calc(20);
                height: auto;
                width: auto;
                display: inline-block;
                padding: 0;
                &::after {
                    display: none !important;
                }
                &.current a {
                    background: none;
                    color:#000;
                }
                a {
                    padding: 8px 14px;
                    position: relative;
                    white-space:nowrap;
                }
            }
            .nav-header {
                background: transparent;
                color:#000;
            }
        }
        .center {
            margin: 0 auto;
        }
        .nav-child {
            @media (min-width: 0px) and (max-width:800px) {
                top: 100px !important;
            }
            @media (min-width: 801px)  {
                top: 130px !important;
            }
            position: fixed;
            border: none;
            max-width: 100%;
            background: $color-light-gray;
            text-align: center;
        }
    }

    .menu li > a:hover   {
        color: $color-gray-black;
    }
    .menu .active > a {
        background: none !important;
    }
}

//Collection mainnav
.item-122 {
    ul.first-sub {
        max-width:60rem !important;
        background-color:transparent;
        &:before {
            content:'';
            background:#eee;
            width: 200vw;
            min-height: 299px;
            height:100%;
            position: absolute;
            left: -75%;
            top:0;
            box-shadow: $blocks-shadow;
        }
        > li {
            width:33%;
            text-align:left;
            float:left;
        }
        > li:nth-child(2) {
            width:27%;
        }
        ul {
            display:block;
            box-shadow:none;
            background-color:transparent;
            position:relative;
            top:auto !important;
            margin-top:0;
            li {
                display:block;
                float:none;
                text-align:left;
            }
        }
    }
    .parent .nav-header {
        //color: #939293 !important;
        cursor: default;
        margin-top:10px;
    }

    .is-dropdown-submenu  {
        li a:hover{
            background: none;
            color: black;
        }
    }
    ul ul  {
        display:block !important;
    }
}
ul.is-accordion-submenu {
    display:none;
}

.topbar__menu .menu .active > a {
    color: #000;
}

//MOBILE
@media (min-width: 0px) and (max-width: 800px) {
    #searchall-searchform {
        margin-top: 7px;
    }
    .com_searchall .search-sources__list, .com_searchall .search-sources__list--result {
        margin-right: 0px !important;
    }
}

//iPAD
@media (min-width: 641px) and (max-width: 1024px) {
    .menu > li > a {
        padding: 0.7rem 0.5rem;
        font-size:rem-calc(18);
    }
    .topbar__logo img {
        max-height:55px;
    }
    .topbar .allsearch-input {
        font-size:rem-calc(14) !important;
        height:40px;
    }
    .topbar .zoeken-icon {
        height:40px;
        width:40px;
    }
}

.menu .is-active > a {
    background: none;
}

.submenu {
	width: 100% !important; // this needed for fix ie
}
