.carousel-container {
	overflow: hidden;
}

.slick-list {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.hp-newsslider {
	background: #fff;
	position: relative;

	h3 {
		background: $color-blue;
		color: #fff;
		display: inline-block;
		font-size: 16px;
	  padding: 4px 10px;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 29;
	}
}

.picturae-carousel {

	.slick-next,
	.slick-prev {
		display: block;
		position: absolute;
		z-index: 29;
		width: 0;
		height: 0;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 22px solid white;
		padding: 0;
		padding-top: 50px;
		margin-bottom: 60%;
		top: 30%;
		line-height: 0;
		align-items: center;
		font-size: 0;
		background: none;
		overflow: hidden;
		outline: none;
		filter: drop-shadow( 0px 0px 2px  rgba(0,0,0,.5));
		cursor: pointer;
	}

	.slick-prev {
		transform: rotate(-90deg);
		left: 0;
	}

	.slick-next {
		transform: rotate(90deg);
		right: 0;
	}

	&__container {
		height: 414px;
		box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

		@media (max-width: 1024px) {
			height: 382px;
			overflow: hidden;
		}
	}

	&__image {
		height: 295px;
		width: 100%;
		display: block;
		overflow: hidden;
		position: relative;

		@media (max-width: 1024px) {
			height: 265px;
		}

		&:before {
			content: "";
			display: block;
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 100;
			width: 0;
			height: 0;
			border-left: 15px solid transparent;
			border-right: 15px solid transparent;
			border-bottom: 15px solid white;
			margin-left: 50%;
			right: 50%;
			align-items: center;
			bottom: 0px;
		}

		img {
			width: 100%;
			height: auto;
			overflow: hidden;
			margin-top: -10%;

			@media (max-width: 1024px) {
				margin-top: 0%;
			}
		}
	}

	h2 {
		font-size: 1.5rem;
		color: $color-blue;
		padding: 10px 20px 0 20px;
	}

	P {
		padding: 0 20px;

		@media (max-width: 1024px) {
			display: none;
		}

		a {
			color: #000;
		}
	}

	.subtitel {
		padding: 0 20px;
		text-align: left;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
		color: #000;
	}

	.slick-dots {
		display: inline-block;
		position: absolute;
		width: auto;
		left: 50%;
		transform: translateX(-50%);
		align-items: center;
		bottom: 10px;
		margin-left: -15px;

		// @media (max-width: 1024px) {
		// 	display: none !important;
		// }

		li {
			display: inline-block;
			border: 2px solid $color-blue;
			border-radius: 100%;
			overflow: hidden;
			width: 15px;
			height: 15px;
			margin: 0 5px 0 10px;
			color: #fff;

			button {
				color: #fff;
				background: #fff;
			}
		}

		.slick-active {
			background: $color-blue;
			color: $color-blue;

			button {
				width: 25px;
				height: 25px;
				margin-top: -5px;
				margin-left: -5px;
				border-radius: 100%;
				background: $color-blue;
				color: $color-blue;
			}
		}
	}
}

.slick-slider {
	height: 100%;
}
