.off-canvas .close-button {
    border: 0;
    background: transparent;
    padding: 0;
    font-size: 1.5em;
    color: #000;
    position: static;
    padding: 17px;
}

.off-canvas {
    background: $color-white;
    z-index: 21;
}

.off-canvas.is-transition-push {
    z-index: 30;
}

//BASIC BOX 300px heigh
.basic-box {
    height: 300px;
    cursor: pointer;
    position: relative;
}

.link-box {
    display: block;
    @extend .basic-box;
}

.homepage {
    //Introduction module
    .intro-text {
        h3 {
            color: $color-blue;
        }
    }
    .intro-text,
    .brd-intro {
        background: #fff;
        height: auto;
        h3 {
            padding: 30px 0 0 30px;
            line-height: 1.2;
        }
        p {
            padding: 30px;
            padding-bottom: 40px;
            font-size: 20px;
            color: $color-paragraph;
            line-height: 1.5;
            word-spacing: 2px;
        }
    }
    //Workshop and stamboom links modules
    .stamboom,
    .workshop {
        a {
            background: transparent;
            border: 1px solid $color-white;
            color: #fff;
            padding: 4px 10px 6px 10px;
            font-weight: 400;
        }
        h2 {
            padding-top: 40px;
            padding-bottom: 26px;
            font-size: 36px;
            line-height: 1.1;
        }
        @include breakpoint(small) {
            display: none;
        }
        @include breakpoint(medium) {
            display: block;
        }
    }
    .meer-informatie-link,
    .workshop {
        background-size: cover;
        background-repeat: no-repeat;
        box-shadow: inset 0 0 0 1000px rgba(0, 110, 182, 0.85);
        text-align: center;
        height: 222px;
        color: $color-white;
        a {
            @extend %transition02sec;
            background-color: $color-blue;
            text-decoration: none;
            font-weight: 700;
            &:hover {
                background: #fff;
                color: $color-blue;
            }
        }
    }
    .submit-button {
        float: right;
        background: rgb(0, 110, 182);
        height: 39px;
        border: 1px solid $color-white;
        font-weight: 700;
        &:hover {
            border: 1px solid $color-white;
            background: #fff;
            color: $color-blue;
        }
    }
    .brd-newsletter {
        padding: 20px;
        background: $color-blue;
        box-shadow: $blocks-shadow;
        color: $color-white;
        text-align: center;
        margin: 21px 0;
        height: 216px;
        line-height: 1.2;
        h2 {
            font-size: rem-calc(32);
            line-height: rem-calc(36);
            margin-bottom: 16px;
        }
        h4 {
            font-size: rem-calc(24);
            line-height: rem-calc(30);
        }
    }
    .aanwinsten img {
        min-height: 300px;
    }
    //Hompage modules "blocks"
    .news-brd,
    .aanwinsten {
        background: $color-white;
        background-repeat: no-repeat;
        height: 299px;
        cursor: pointer;
        & img {
            min-height: 300px;
        }
        .aanwinsten a {
            color: $color-white;
        }
    }
    //To hide overflow of background image in modules
    .brd-article-image {
        overflow: hidden;
        height: 300px;
    }
    .stadsarchief {
        background-repeat: no-repeat;
        background-size: cover;
        height: 300px;
        cursor: pointer;
        h2 {
            background: $color-white;
            color: $color-blue;
            height: auto;
            width: auto;
            font-size: 24px;
            padding: 20px 0 20px 20px;
            position: relative;
            top: 149px;
            &::before {
                content: url(../svg/arrow-up.svg);
                vertical-align: middle;
                margin: -40px 37%;
                position: absolute;
                padding: 0;
            }
        }
    }
    .brd-bouw {
        background-size: contain !important;
        background-repeat: no-repeat;
        background-position: center top;
        height: 268px;
        text-decoration: none;
        color: white;
        cursor: pointer;
        a {
            color: $color-white;
            line-height: 1.0;
        }
        @include breakpoint(small) {
            display: none;
        }
        @include breakpoint(medium) {
            display: block;
        }
    }
    //Top left corner label in blue bg white text
    .label-left {
        background: $color-blue;
        color: $color-white;
        width: auto;
        height: 30px;
        padding: 3px 10px 0;
        position: absolute;
        font-size: 16px;
        z-index: 20;
    }
    //WHITE BOXES
    .hp-newsbox,
    .blog-brd {
        @extend .basic-box;
        text-decoration: none;
        h3 {
            @extend .label-left;
        }
        h2 {
            @extend %block-basic; //see elements.scss
            background: $color-white;
            &::before {
                background: url('../svg/arrow-up.svg') no-repeat;
            }
        }
        img {
            width: 100%;
        }
        .blue-hover {
            position: absolute;
            height: 300px;
            width: 100%;
            top: 0;
        }
    }
    //Module description
    .blue-bg-blue {
        position: relative;
        h2 {
            @extend %block-basic; //see elements.scss
            background: $color-blue;
            color: #fff;
            z-index: 820;
            &::before {
                background: url('../svg/arrow-upblue.svg') no-repeat;
            }
        }
        .box-link {
            &:hover h2 {
                color: $color-lightblue;
            }
        }
    }
    //Gutter for all
    .main-content .moduletable {
        box-shadow: $blocks-shadow;
        @include module-gutter();
    }
    //Add black color hover, for modules subtitles
    .blog-brd:hover,
    .stadsarchief:hover,
    .hp-newsbox:hover {
        h2 {
            color: $color-black;
        }
    }
    .brd-article-author,
    .brd-article-introtext {
        color: red;
        margin-top: -134px;
        display: none;
    }
    //Popup more scans
    .ui-icon.ui-icon-closethick {
        color: #fff;
    }
}