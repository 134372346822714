//GREYS
$color-black: #000;
$color-white: #fff;
$color-white-hover: #cacaca;
$color-gray: #E5E5E5;
$color-gray-bg-hover: $color-gray;
$color-gray-black: #333;
$color-light-gray: #eee;
$color-dark-gray: #a0a0a0;
$color-breadcrumb-grey: #888;
$color-text: $color-gray-black;
$color-paragraph: #262626;

//BLUES
$color-blue: #006eb6;
$color-blue-hover: #0499fb;
$color-button-hover: #abd0e9;
$color-lightblue: #d9f0ff;