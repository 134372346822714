.item-page,
.introduction-article {
    background: $color-white;
    padding: 8px 20px 30px;
    margin-bottom: 40px;
    box-shadow: $blocks-shadow;
    position: relative;
    h1 {
        font-size: rem-calc(32);
    }
    h2 {
        color: $color-blue;
        font-size: rem-calc(32);
    }
    h3,
    h4 {
        font-size: rem-calc(24);
        padding: 16px 0px 0px;
    }
    h4 {
        color: $color-blue;
    }
    p {
        padding: 10px 0;
        font-size: rem-calc(18);
    }
    p a {
        text-decoration: underline;
    }
    ol,
    ul {
        font-size: rem-calc(18);
        margin-left: 28px;
        li {
            padding: 3px 0 3px 6px;
        }
    }
    ol li {
        list-style-type: decimal;
    }
    ul li {
        list-style-type: disc;
    }
    img {
        margin: 20px;
        max-width: 100%;
    }
    img.pull-right {
        margin: 0 0 0 20px;
    }
    img.pull-left {
        margin: 0 20px 0 0;
    }
    //Back button categories overview
    .back-button {
        display: block;
        text-align: right;
        right: 20px;
        margin: -40px 0 20px;
        a {
            padding-left: 15px;
            display: inline-block;
            font-size: rem-calc(18);
            background: url(../img/goback-arrow.svg) no-repeat center left;
            &:hover {
                background: url(../img/goback-arrow-black.svg) no-repeat center left;
            }
        }
    }
}

//BACK-button exceptions
.com_content.actueel,
.com_content.verdieping,
.com_content.informatie {
    .back-button {
        display: none;
    }
}

//Test
.introduction-article {
    @include clearfix;
    background: white;
    margin-bottom: 20px;
    .collapse-article {
        &::before {
            content: url('../img/collapse-arrow-up.svg');
            float: right;
            margin-top: -40px;
        }
        &.closed::before {
            content: url('../img/collapse-arrow-down.svg');
        }
    }
    .article-content {
        display: block;
    }
    .article-content.open {
        display: none;
    }
}

//Styling custom voor overzicht van aanwinsten - bibliotheek en foto's en film - class opgenomen in joomla menu
.brd-cust-overview {
    div[itemprop="articleBody"],
    .rnm {
        table {
            td:first-child {
                p {
                    padding: 0;
                }
                img {
                    margin: 16px 0 10px;
                }
            }
            @include breakpoint(small only) {
                width: 100% !important;
                tr {
                    display: flex;
                    flex-direction: column;
                    height: auto !important;
                    td {
                        display: block;
                        height: auto !important;
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

iframe {
    @include breakpoint(small only) {
        width: 100% !important;
    }
}