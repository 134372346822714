.item-page table {
    tbody {
        border:none;
    }
    th, td {
        padding:0;
        vertical-align: middle;
    }
    img {
        margin:7px 12px 0;
        line-height:0;
    }    
}