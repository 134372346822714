//BEELDBANK
.pic-mediabank {
    font-family: 'Lato', sans-serif;
    .mediabank-search .mediabank-search-term {
        &::-moz-placeholder {
            color: $color-blue;
            opacity:1;
            font-size:rem-calc(20);
        }
    }
    .mediabank-filter {
        font-size:rem-calc(20);
    }
    .mediabank-result-header {
        background-color:transparent;
        margin-bottom:-66px;
        .mediabank-results-total {
            color:$color-text;
            font-size:rem-calc(18);
            font-weight:400;
            .total {
                font-weight:700;
            }
        }
    }
}
.mediabank-gallery-tooltip ul {
    max-height:238px;
    overflow:hidden;
    li {
        font-size: rem-calc(14px);
        line-height: rem-calc(16px);
    }
}


//mediabank-library collectie
.mediabank-library.collectie {
    .mediabank-gallery-focused .mediabank-focused-right {
        display: block;
        width: 100%;
        margin-top: 20px;
    }
    .mediabank-result-options {
        border-bottom:none;
    }
}
@media only screen and (max-width: 768px) {
    .mediabank-library.collectie .mediabank-search .mediabank-search-fieldset {
        width: 80%;

    }
}

//MEDIABANK SEARCH
.mediabank-search {
    .mediabank-search-term {
        width: 65%;
        margin: 0 auto;
        height: 50px;
        font-size: 20px;
        color: $color-blue;
    }
    .mediabank-search-submit {
        background: url('../img/search-icon.svg') no-repeat center $color-blue;
        text-indent: 30px;
        width: 50px;
        height: 50px;
        border: 1px solid #fff;
        color: $color-blue;
        margin-right: 23.5%;
        outline: none;
        &:hover {
            background: url('../img/search-icon-blue.svg') no-repeat center #fff;
        }
    }
}
.mediabank-search,
.mediabank-detail-menu{
    background: $color-blue;
}
.mediabank-detail-menu {
    li:hover {
        background:#fff;
        a i {
           color: $color-blue;
        }
    }
}


//MEDIABANK search help button
.com_mediabank .mediabank-search .mediabank-search-help {
    background: $color-blue;
    border:1px solid #fff;
    color: #fff;
    font-size: 1.35rem;
    line-height: 46px;
    height: 50px;
    width:50px;
    right: -56px;
    &:hover {
        color:$color-blue;
        background: #fff;
    }
}

.mediabank-thumb-pagination a.disabled:hover {
    color: $color-white;
    background: $color-button-hover;
}

.mediabank-gallery-mode .active a,
.mediabank-detail-menu li.active a {
    color: $color-blue !important;
}

.mediabank-detail-panel-close {
    margin:12px 12px 0 0;
    border:0;
    background-color:$color-blue;
    width:32px;
    height:32px;
    &::before {
        border-radius:50%;
        width:32px;
        height:32px;
        background:url('../img/close-white-sml.svg') center center no-repeat;
        content:'';
        display:block;
        position:relative !important;
        right:2px;
        left:0;
        top:0px;
        bottom:0;
    }
    &:hover {
        background-color:#000;
    }
    .pic-icon-close {
        display:none;
    }
}

//PAGINATION
//Result background
.pic-mediabank .mediabank-result-header {
    margin-bottom:0;
    background-color:$color-gray;
    .pagination-jump {
        display:inline;
        font-size: 1.125rem
    }
}
.mediabank-pagination {
    font-size: 1.125rem
}
.mediabank-pagination .pagination-button-prev,
.mediabank-pagination .pagination-button-next,
.mediabank-button-back {
    background: $color-blue;
    &:hover {
        background: #fff;
        color: $color-blue;
    }
}
//Detailpage - search and pagination
.mediabank-mode-detail {
    .mediabank-button-back,
    .mediabank-pagination .pagination-button-prev,
    .mediabank-pagination .pagination-button-next {
        height:50px;
        border:1px solid #fff;
    }
    .mediabank-pagination .pagination-button-prev,
    .mediabank-pagination .pagination-button-next {
        width:50px;
        line-height:25px;
    }
    .mediabank-button-back {
        font-size: 1.25rem;
    }
}
.mediabank-gallery-sort .mediabank-sort-label {
    margin-top:5px;
}

.mediabank-sort-select {
    width: 175px;
}

.mediabank-gallery-mode a {
    color: #bbb;
    &:hover {
        color: $color-blue;
    }
}

.comment-success {
    background: $color-lightblue;
    margin:12px 0;
    font-size: 1.25rem !important;
}

.mediabank-focused-close {
    label {
        display:none;
    }
}

//Drop down menus
.mediabank-filter-list {
    > li {
        margin-bottom: 8px;
        margin-right: 8px;
        div {
            font-size:rem-calc(16) !important;
        }
    }
    .mediabank-filter {
        padding:3px;
        border:2px solid $color-blue;
        background-color:$color-blue;
        color:#fff;
        &:hover {
            background-color:#fff;
            color:$color-blue;
        }
    }
}

.mediabank-gallery-sort {
    .mediabank-sort-select {
        margin:-3px 0 5px;
    }
    li {
        background: $color-blue;
        border:2px solid $color-blue;
        &:hover {
            color: $color-blue;
            background: #fff;
        }
    }
}
.mediabank-filter-dropdown {
    background: #fff;
    .mediabank-filter-search input[type="text"] {
        border:1px solid $color-blue;
        color:#000;
    }
    .filter-search-icon {
        color:$color-blue;
    }
    a {
        color:$color-blue;
        text-decoration:underline;
        &:hover {
            color:#000;

        }
        .filter-count {
            color: #888;
        }
    }

    .mediabank-page-prev, .mediabank-page-next {
        background: $color-blue;
        border:2px solid #fff;
        line-height:30px;
        &:hover {
            background: #fff;
            color:$color-blue;
            border:2px solid $color-blue;
        }
        i {

        }
    }
}

.mediabank-gallery-sort li:last-child {
    border-bottom:2px solid $color-blue;
}

//Left panel social media icons
.mediabank-filters-active .filter-active,
.pic-load-previous-container .pic-load-previous {
    background: $color-blue;
    &:hover {
        background:$color-button-hover;
    }
}

.mediabank-thumb-pagination a {
    background: $color-blue;
}

//Detail page
.mediabank-container.mediabank-mode-detail {
    margin-top: 130px !important;
    .mediabank-search .mediabank-search-submit {
        margin-right: 22.5%;
    }
}
.mediabank-gallery-focused {
    .mediabank-detail-button, .mediabank-focused-close {
        background: $color-blue;
        &:hover {
            background: $color-button-hover;
        }
    }
}
.mediabank-detail-comments {
    h3 {
        margin:4px 0 6px;
    }
}
.mediabank-comment-form {
    input[type="text"], textarea {
        border: 1px solid $color-blue;
        &::-moz-placeholder {
            color: $color-blue;
            opacity:1;
            font-size:rem-calc(16);
        }
    }
    .mediabank-comment-button {
        background-color:$color-blue;
        &:hover {
            background-color:#000;
        }
    }
}
.mediabank-comment-overview {
    h3 {
        background-color:$color-light-gray;
    }
}

//Images box
.pic-image-selection {
    section {
        border:none;
        border-top:1px solid $color-gray;
        border-bottom:1px solid $color-gray;
    }
    .theme-images {
        margin:0;
        padding:0;
        list-style:none;
        li {
            display:inline-block;
            padding:0;
        }
    }
    img {
        width: 170px;
        display: inline-block;
        margin: 12px 6px;
        border: 1px solid $color-gray;
        padding: 10px;
        height: 100%;
        &:hover {
            border: 1px solid $color-blue;
        }
    }
    .more {
        width:100%;
        text-align:center;
        margin-bottom:20px;
    }
}
//BRD-custom
.mediabank-detail-view .mediabank-webexposition-button {
    background-color: $color-blue;
    &:hover {
        background-color: $color-blue;
    }
}

.mediabank-detail-panel-button i .pic-icon-comment-count {
	background-color: $color-breadcrumb-grey;
	color: #000;
}

.com_mediabank {
	#pic-mediabank-add-comment {
		background: $color-blue;

		&:hover {
			background: #000;
		}
	}
	.mediabank-permalink-copy {
		background: $color-blue;

		&:hover {
			background: $color-blue-hover;
		}
	}

   #download_popup {
	   	border: none !important;
		.item-page {
			box-shadow: none;
		}
		.close-button {
			z-index: 5;
			background-color: $color-blue;
			border: none;
			span {
				color: #fff;
				font-size: 42px;
				padding-left: 2px;
				padding-right: 2px;
			}
		}
	 	.download_link #download_higres {
		  background: #006eb6;
		  color: #fff;
		  padding: 7px 17px;
		  margin-top: 10px;
		  display: inline-block;
		  font-size: 16px;
		}
   }
}
.conditions {
    display:none;
}
.contact_rechthebbende {
    display:none;
}
#label_download_terms {
    margin: 0;
    color: black;
}
