#system-message-container {
    // @include z-index(system-message);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: rem-calc(20);
    // background: $color-yellow;

    h4 {
        font-size: rem-calc(24);
    }

    p {
        font-size: rem-calc(18);
    }

    .alert {
        position: relative;
    }
    
    .close {
        position: absolute;
        right: -20px;
        top: -20px;
        padding-top: 6px;
        width: 40px;
        height: 40px;
        text-align: center;
        text-decoration: none;
        font-size: 30px;
        line-height: 20px;
        color: #fff;

        &:hover {
            cursor: pointer;
        }
    }
}
