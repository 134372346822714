a {
    color:$color-blue;
    //text-decoration: underline;
    &:hover {
        color:#000;
        text-decoration: none;
    }    
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    font-family: 'Lato', sans-serif;
}