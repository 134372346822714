.com_searchall {
    .website-result-title {
        text-align: left;
        background: white;
        position: relative;
        padding: 10px 30px;
        margin-top: 29px;
        color: $color-blue;
    }
    .search-header {
        h1 {
            font-size: 2rem;
            color: $color-blue;
            padding: 8px 0px 0px 8px;
        }
    }
    .search-sources {
        background: #fff;
        padding: 20px;
        box-shadow: $blocks-shadow;
        &__title {
            background: $color-blue;
            color: #fff;
            font-size: rem-calc(18);
            line-height: rem-calc(22);
            padding: 0 10px;
            height: 60px;
            width:100% !important;
            text-align: center;
            display:table;
            border:2px solid $color-blue;
            span {
                vertical-align: middle;
            }
            .cellblock {
                height: 60px;
                display:table-cell;
                vertical-align: middle;
            }
        }
         .atlantis-data-container:hover, a:hover {
            .search-sources__title {
                background: #fff;
                border:2px solid $color-blue;
                color:$color-blue;
            }
        }
        &__count {
            background: $color-light-gray;
            font-size: 46px;
            margin-bottom: 9px;
            color: $color-black;
            text-align: center;
            padding: 25px;
            input {
                border: none;
                background:transparent;
            }
        }
        &__list, &__list--result {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            margin-right: -38px;
        }
        &__count--mobile {
                display: none;
            }
        .l-container ul li {
            width: 23%;
            margin: 0.6%;
            @include breakpoint(small only) {
                width: 100%;
            }
            @include breakpoint(medium only) {
                width: 40%;
            }
        }
    }
    
    //Hide warning only for localhost
    .xdebug-error, .xe-notice, #system-message-container {
        display: none;
    }



    //Search items
    .website-results {
        padding:0 18px;
        &__list {
            display: flex;
            align-content: stretch;
            flex-direction: row;
            flex-wrap: wrap;
            li {
                box-sizing: border-box;
                width:31.333%;
                float: left;
                background: #fff;
                margin:6px 1%;
                font-size: 18px;
                background: $color-light-gray;
                padding: 15px;
            }
            a {
                float: left;
                padding-bottom: 6px;
                line-height: 1.2;
                &:hover {
                    color:$color-text;
                }
            }
            .website-result a:nth-child(2) {
                font-size:rem-calc(14);
                clear:both;
                color:$color-gray-black;
            }
        }
    }

    .search-pagination {
        width:100%;
        height:40px;
        clear:both;
        text-align:center;
        &:hover {
            color:$color-text;
        }
    }

    .fsf {
        background: #fff;
        box-shadow: $blocks-shadow;
        padding-bottom: 10px;
    }

    section {
        background: $color-white;
       /* h1 {
            color: $color-blue;
            top: 10px;
            left: 10px;
            position: relative;
        } */
        h3 {
            font-size: 18px;
            top: 5px;
            left: 10px;
            position: relative;

            b {
                font-size: 30px;
            }
        }
    }
}
