.sidebar-menu  {
    height: 100%;
    .moduletable {
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.3);
    }
    @include breakpoint(small) {
        display:none;
    }    
    @include breakpoint(medium) {
        display:block;            
    }  
    ul {
        box-shadow:none;
    }
    li {
        display: inline-block;
        width: 100%;
        background: $color-gray;
        font-size: 20px;

        &:last-child {
            padding-bottom: 12px;
        }
        a:hover, a:active {
            color:#fff;
            background: $color-blue;
        }
        &.active a {
            background: $color-blue;
        }
    }
    
    //Background sidebar menu title
    h3 {
        background: #000;
        color: $color-white;
        font-size: 20px;
        font-weight: 500;
        height: 50px;
        padding: 13px 0px 0 15px;
        a {
            color: #fff;
            background: $color-blue !important;
            padding:0;
        }
    } 
}
