/*
 * Micro clearfix hack
 * http://nicolasgallagher.com/micro-clearfix-hack/
 */
@mixin clearfix() {
  zoom: 1;

  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear:both;
  }
}

/*
 * This hide-text is supposed to have better performance then the -9999px technique
 * http://www.zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement/
 */
@mixin hide-text() {
  text-indent: 110%;
  white-space: nowrap;
  overflow: hidden;
}

// Only visible for screen readers
@mixin visually-hidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin button-reset() {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  border: none;
}

@mixin ul-reset() {
  margin: 0;
  padding: 0;
  list-style: none;
}

// Center element inside its parent
@mixin center() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// For use with the gulp sprite plugin
@mixin sprite($icon-name) {
  $icon: map-get($icons, $icon-name);
  $sprite: map-get($icons, sprite);

  width: map-get($icon, width);
  height: map-get($icon, height);
  background-image: url(map-get($sprite, svgPath));
  background-position: map-get($icon, backgroundX) map-get($icon, backgroundY);
}

@mixin hover-transition() {
  transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
}

@mixin input-placeholder() {
  ::-webkit-input-placeholder {
    @content;
  }

  :-moz-placeholder { /* Firefox 18- */
    @content;
  }

  ::-moz-placeholder {  /* Firefox 19+ */
    @content;
  }

  :-ms-input-placeholder {
    @content;
  }
}

// Add percentage of white to a color
@function tint($color, $percent) {
  @return mix(white, $color, $percent);
}

// Add percentage of black to a color
@function shade($color, $percent) {
  @return mix(black, $color, $percent);
}

@mixin view-link($color: $color-text) {
  display: inline-block;
  color: $color;
  font-size: rem-calc(13);

  &::after {
    content: '';
    margin-left: 10px;
    font-family: 'FontAwesome';
    font-size: 16px;
  }

  &:hover {
    background: none;
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

// Creates CSS only triangle
@mixin triangle($dir, $size, $color) {
    border: $size transparent solid;

    @if ($dir == "top" or $dir == "up") {
        border-bottom-color: $color;
    }
    @else if ($dir == "right" or $dir == "after") {
        border-left-color: $color;
    }
    @else if ($dir == "bottom" or $dir == "down") {
        border-top-color: $color;
    }
    @else if ($dir == "left" or $dir == "before") {
        border-right-color: $color;
    }
}

@mixin module-gutter(
  $gutter: auto,
  $gutters: $grid-column-gutter
) {
  @include -zf-breakpoint-value($gutter, $gutters) {
    $margin: rem-calc($-zf-bp-value) / 1.4;

    margin-top: $margin;
    margin-bottom: $margin;
  }
}
