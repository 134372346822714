// 1. Global
// ---------

$foundation-palette: (
  primary: #006eb6,
  secondary: #767676,
  success: #3adb76,
  warning: #ffae00,
  alert: #cc4b37,
);

// 2. Breakpoints
// --------------

$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1200px,
  xxlarge: 1440px,
);
$breakpoint-classes: (small medium large);

.foundation-mq {
  font-family: '#{-zf-bp-serialize($breakpoints)}';
}
// 3. The Grid
// -----------

$grid-row-width: rem-calc(1200);
$grid-column-count: 12;
$grid-column-responsive-gutter: (
  small: 20px,
  medium: 30px,
);
$grid-column-align-edge: true;
$block-grid-max: 8;

// Font-family
$font-family-sans-serif: 'Lato', sans-serif;


// 18. Dropdown Menu
// -----------------

$dropdownmenu-arrows: false;
$dropdownmenu-arrow-color: $anchor-color;
$dropdownmenu-arrow-size: 6px;
$dropdownmenu-min-width: 200px;
$dropdownmenu-background: $white;
$dropdownmenu-border: 1px solid $medium-gray;

// 24. Off-canvas
// --------------

//$offcanvas-size: 250px;
$offcanvas-background: $light-gray;
$offcanvas-zindex: -1;
$offcanvas-transition-length: 0.5s;
$offcanvas-transition-timing: ease;
$offcanvas-fixed-reveal: true;
$offcanvas-exit-background: rgba($white, 0.25);
$maincontent-class: 'off-canvas-content';
$maincontent-shadow: 0 0 10px rgba($black, 0.5);
