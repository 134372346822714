.clearfix {
    @include clearfix();
}

.visually-hidden {
    @include visually-hidden();
}

.right {
    float: right;
}

.left {
    float: left;
}
