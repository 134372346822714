//IE11
:-ms-input-placeholder { /* IE10–11 needs important tag */
    color: $color-blue !important;
}
:-ms-input-placeholder:focus { /* IE10–11 needs important tag */
    color: $color-blue !important;
}
//END IE11


.genealogy-table th.sortable, .search-form.genealogy .json-form-buttons .search-button  {
    background: $color-blue;
}
//Remove bottom marning for result bar
.picturae-page-navigation {
    margin-bottom: 0;
}
.searchboxwrapper {
    background: $color-blue;
    border: none;
    min-height: none;
}

//SEARCH FORM
#simple-search {
    width:100%;
    .form-control input {
        height:50px;
        font-size: 20px;
        color: $color-blue;
        @include breakpoint(small){
            margin:0 10%;
            width:80% !important;
        }
        @include breakpoint(medium){
            margin:0 25%;
            width:50% !important;
        }
    }
}
.search-form.genealogy {
    position: relative;
    margin: 0 auto;
    .json-form-buttons {
        width:50px !important;
        position:absolute;
        top:0px;
        @include breakpoint(small){
            right:10%;
        }
        @include breakpoint(medium){
            right:24%;
        }
        .search-button {
            border: 1px solid white;
            min-height: 50px;

            border: 1px solid white;
            background-image: url(../img/search-icon.svg);
            background-repeat: no-repeat;
            text-indent: 153px;
            background-position: 13px;
        }
    }    
    .json-form-buttons .search-button:hover, 
    #genealogy-search-button:hover, 
    #genealogy-advanced-search-button:hover {
        background-image: url(../img/search-icon-blue.svg);
        cursor: pointer;
        background-color: #fff;
        color:$color-blue;
    }
    .search-help {
        background-color: $color-blue;
        max-width: 50px;
        padding: 14px 22px;
        font-size: 28px;
        font-weight: bold;
        border: 1px solid white;
        color: white;
        position:absolute;
        top:-3px;
        right: calc(24% - 60px);
        z-index: auto;
        text-indent: -3px;
        &:hover {
            background: #fff;
            color:$color-blue;
        }
        @include breakpoint(small) {
            display:none;
        }
        @include breakpoint(medium) {
            display:block;
        }
    }
    .json-form-fields input {
        height: 48px;
        &::-moz-placeholder {
            color: $color-blue;
            opacity:1;
            font-size:rem-calc(20);
        }
    }
}

#simple-search {
    .search-form.genealogy .json-form-buttons .search-button {
        width: 50px !important;
    }
}
#advanced-search {
    .search-form.genealogy {
        .json-form-buttons {
            width:auto;
            position:relative;
            right:auto;
            width: 250px !important;
        }
    }        
}

//Help buttons inside the input field
.search-extended.pic-button {
    //background: $color-blue;
    background-color: transparent;
    border: 1px solid $color-white;
    width: 50px;
    height: 50px;
    margin: 0;
    padding: 12px;
    top:0px;
    &:hover {
        background-color: #fff;
        color: $color-blue;
    }
    &.active {
        background: $color-blue;   
    }
}

.picturae-pagination {
    .previous-button::before, .next-button::before {
        width:40px;
        height:40px;
        display:block;
        top:0;
        left:0;
        border:1px solid red;
    }
    .previous-button::before {
        background:url('../img/arrow-white-left.svg') no-repeat $color-blue center;
    }    
    .next-button::before {
        background:url('../img/arrow-white-right.svg') no-repeat $color-blue center;
    }
    .previous-button:hover::before {
        background:url('../img/arrow-black-left.svg') no-repeat #fff center;
    }    
    .next-button:hover::before {
        background:url('../img/arrow-black-right.svg') no-repeat #fff center;
    } 
}

.genealogy-topviewer-dialog .viewer-close-button {
    background:$color-blue;
    border-radius:0;
    transition:none;
    &:hover {
        color:#000;
        background-color:#fff !important;
        opacity:1;
        .pic-icon-left-open-mini {
            background:url('../img/arrow-black-left.svg') no-repeat center;
        }
    }
    .pic-icon-left-open-mini {
        background:url('../img/arrow-white-left.svg') no-repeat center;
    }
}

.genealogy-search #main-form .json-form-fields {
    width: 100% !important;
    float:none;
}

.genealogy-searchhelp ul li, .searchhelp ul li { 
    span {
        display: inline-block;
        span {
            padding-left: 0;
        }
    }
    em {
        color: $color-blue;
    }
}

.genealogy-searchhelp ul li:nth-child(even), .searchhelp ul li:nth-child(even) {
    background-color: rgba(171, 208, 233, 0.28);
}

.back.ng-scope a i {
    border-radius: 0px;
}

//Advance search page, search button
#advanced-search {
    .search-form.genealogy .json-form-buttons .search-button {
        background-image: none;
        text-indent: 0;
        margin: 15px 0;
    }
}
.genealogy-table tr:hover {
    background: $color-lightblue;
}
.pagination-position-bottom {
    margin-bottom: 10%;
}

.tablerecord.active ol, .marriage-record ol, .birth-record ol, .registration-record ol {
    background: $color-gray;
    padding: 10px 0;
    border: none;
}


//Hover table header
.genealogy-table {
    thead {
        background: $color-blue;
    }
    th.sortable.sort-desc {
        background: url(../img/picturae-icons/desc-marge-white.png) no-repeat center right $color-button-hover;
    }
    th.sortable.sort-asc {
        background: url(../img/picturae-icons/asc-marge-white.png) no-repeat center right $color-button-hover;
    }
    th div {
        font-weight: 700;
    }
    td {
        padding: 8px 20px;
    }
    td.person, td.register {
        color: $color-paragraph;
    }
    td.person, .genealogy-table td.register {
        color: $color-paragraph;
    }
    tr:nth-child(odd) {
        background-color: #f0f0f0;
    }
    tr:nth-child(odd) {
        background-color: #fff;
    }
    tr.visited {
        background: $color-lightblue;
        border-top: 1px solid #fff;
        &:hover {
            background: $color-button-hover;
        }
    }
    tr:hover {
        background: $color-button-hover;
    }
}

.filters.genealogy .filter-default {
    width:100%;
    h3 {
        background-color: $color-gray;
        padding:10px 0;
    }
}

td .tablerecord.active {
    border: none;
    padding: 0 20px 20px;
}

//Result page title
.pagination-resuts {
    line-height: 34px;
    float: left;
    font-weight: 700;
}

//Add white text color for advance rseult page label
#registers-advanced-form .form-control {
    label {
        color: $color-white;
    }
    input {
        margin: 4px 0px !important;
    }
}
#persons-advanced-form, #registers-advanced-form {
    .form-control label {
        color: $color-white;
    }
}

.form-control {
    select {
        min-height: 50px;
    }
    .panel input {
        min-height: 48px;
    }
}

.pagination-button-list .pic-button:hover {
    background:$color-button-hover !important;
}
.pic-button, .tablerecord .more a {
    border-radius: 0px;
}
.record-scource, .non-related {
    border-radius: 0px;
    background-color: $color-lightblue;
}

//P
.tablerecord .registration {
    background: $color-lightblue;
    border: none;
    .registered-person {
        padding: 10px 10px 10px 15px;
        margin: 20px 0 20px 20px;
        background: #fff;
        box-shadow: -2px 2px 0px 0px #a9bcc9;
    }
}
.picturae-genealogy-container {
    a {
        color: $color-blue;
        text-decoration: underline;
        &:hover {
            color: $color-paragraph;
        }
    }
    .loading {
        background: $color-blue;
    }
    .mmx-report-button {
        background: $color-blue;
        border-radius:0;
        width: auto;
        margin-right:25px;
        &:hover {
            color: $color-blue;
            background: $color-lightblue;
        }
    }
}

.tablerecord .more a {
    background: $color-blue;
    &:hover {
        background-color: $color-button-hover;
    }
}

.genealogy-topviewer-dialog {
    .ngdialog-content {
        display: block;
        position: absolute;
        top: 130px;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .viewer-close-button {
        background: $color-blue;
        &:hover {
            background-color: $color-button-hover;
        }
        i {
            margin-top:-8px;
        }
    }
    .metadata-button, .download-button, .fromdeed-button {
        background: $color-blue;
        margin-top: 15px;
        &:hover {
            color: $color-button-hover;
            border: 2px solid #fff;
        }
        i {
            margin-top:-4px;
        }
    }
}

.genealogy-metadata-panel {
    .metadata-close-button {
        background: $color-blue;
        &:hover {
            background: $color-button-hover;
        }
    }
    h1 {
        margin-top: 16px;
    }    
}

.com_genealogy {
    //This is a tweak for a safari bug
    .medium-3 {
        flex: 0 0 24%;
    }
    .introduction-article {
        background: white;
        padding: 8px 20px 20px;
        width: 102.5%;
        margin: 0px -15px 21px;
        h4 {
            color: $color-blue;
            font-weight: bold;
            padding-bottom: 10px;
        }
    }
}

.ngdialog-mmx-report.ngdialog.ngdialog-theme-plain {
    .ngdialog-content button {
        background: $color-blue;
        border-radius:0;
        &:hover {
            background: $color-button-hover;
        }
    }
    .ngdialog-close {
        background:transparent;
        &:hover {
            background:transparent;
        }
    }
    .ngdialog-content input, .ngdialog-content textarea {
        border: 1px solid $color-blue;
        color:$color-text;
    }
    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $color-blue;
        font-size: 1rem;
        opacity: 1;
    }
    ::-moz-placeholder { /* Firefox 19+ */
        color: $color-blue;
        font-size: 1rem;
        opacity: 1;
    }
    :-ms-input-placeholder { /* IE 10+ */
        color: $color-blue;
        font-size: 1rem;
        opacity: 1;
    }
    textarea[name="body"]::-moz-placeholder, 
    textarea::-moz-placeholder,
    textarea::-webkit-input-placeholder {
        color: $color-blue;
        font-size: 1rem;
        opacity: 1;
    } 
}

//Advance search help
.searchhelp {
    background: $color-white;
    margin-top: 20px;
}

slider div.bar .selection, [slider] div.bar .selection {
    width: 0%;
    z-index: 1;
    background: #abd0e9;
    height: 10px;
    border: none;
    border-radius: 0;
}

.back.ng-scope a i {
    background: $color-blue;
    margin-left: 10px;
    &:hover {
        background: $color-button-hover;
    }
}

//View deed result page
.tablerecord aside {
    margin-bottom: 10px;
    margin-right: 25px;
}

//Overwrite background color
.tablerecord.active:hover, 
.marriage-record:hover, 
.birth-record:hover, 
.registration-record:hover {
    background: #fff;
}

//ACTIONS
.record-actions .view a.image {
    padding: 0 !important;
    background: $color-blue;
    border-radius:0;
    &:hover {
        background: $color-lightblue;
    }
}

.record-actions a:hover {
    background-color: $color-button-hover;
}

ul.registration-info-list {
    margin: 0 10px 20px;
}
.social-media-share ul li {
    height:40px;
    .facebookCount {
        display: none;
    }
}
.social-media-share ul li .facebookButton {
    height:40px !important;
}
//Search help page indent orange text result
.searchhelp ul li em {
    margin: 0 8px;
}

.filters.genealogy .filter-default .filter-box.active, 
.filters.genealogy .filter-box-external {
    background: #fff;
    text-align: left;
    left:0;
}
.slider-container table.period-chart .labels td .label {
    color: $color-paragraph;
}

//period chart
.slider-container table.period-chart .markers td {
    height: 8px;
    border-bottom: 1px solid $color-blue;
    border-left: 1px solid $color-blue;
    border-right: 1px solid $color-blue;
}

.slider, [slider] { 
    div.bar .selection {
    background: $color-blue;
    }
}    

.search-form.genealogy .json-form-fields div[data-schemapath="root.person_1"]>h3, 
.search-form.genealogy .json-form-fields div[data-schemapath="root.person_2"]>h3 {
    display: inherit;
    margin: 20px 0 13px;
    color: white;
    width: 300px;
}

label {
    color: $color-white;
}

.custom-form-field-label, .custom-form-field-input  {
    label {
        color: #0a0a0a;
    }
}

//Remove height
.filters.genealogy {
    background: $color-white;
    h3 {
        padding-left: 10px;
        padding-top: 5px;
    }
    li .btn-danger {
        margin-top: 7px;
        padding-right: 15px !important;
    }
    #filtered {
        min-height: 0px;
        margin: 0;
        ul li a {
            background: $color-blue;
            &:hover {
                background: $color-button-hover;
            }
        }        
    }
    .filter-list {
        a {
            text-decoration: underline;
        }        
        li {
            text-align: left;
            &:hover {
                background: none;
            }
        }
    }
}

//filters
.filters.genealogy .pic-filter-container {
    background:#fff;
    .filter-header {
        padding:3px;
            h3 {
            padding:3px;
            border:2px solid $color-blue;
            color:#fff;
            background: url(../img/dropdown-small-marge.png) no-repeat center right $color-blue;
            text-transform:none;
            font-size:rem-calc(16);
            &:hover {
                background: url(../img/dropdown-small-marge-h.png) no-repeat center right #fff;
                color:$color-blue;
            }
        }
        &.active {
            h3 {
                background: url(../img/dropdown-small-marge-h.png) no-repeat center right #fff;
                color:$color-blue; 
            }
        }
    }
    table thead, table tbody, table tfoot {
        background-color: transparent;
    }
}

.ngdialog.ngdialog-theme-plain .ngdialog-content {
    padding:12px 28px 28px;
    .dialog-contents {
        font-family: 'Lato', sans-serif;
        .count {
            color:#777 !important;
        }
    }
    .picturae-page-navigation {
        display:none;
    }
    .ngdialog-close {
        margin:12px 12px 0 0;
        border:0;
        background-color:$color-blue;
        width:32px;
        height:32px;
        border-radius:50%;
        &::before {
            border-radius:50%;
            width:32px;
            height:32px;
            background:url('../img/close-white-sml.svg') center center no-repeat;
            content:'';
            display:block;
            position:relative !important;
            right:2px;
            left:0;
            top:2px;
            bottom:0;
        }
        &:hover {
            background-color:#000;
        }
    }
}


//Result page
#persons-advanced-form, #registers-advanced-form {
    select {
        font-size: 1rem;
        margin-top: 3px;
        padding: 0 !important;
    }
}

//Extra styling genealogy update - may2018
#advanced-search-button {
    width:auto;
    position: absolute;
    right: calc(24% - 110px);
}


.genealogy-filter-search {
  input {
    border:2px solid $color-blue !important;
  }
}
.filters.genealogy .genealogy-filter-search .filter-search-icon {
    top: 19px;
    color: $color-blue;
    @include breakpoint(medium) {
      top: 5px;
    }
}

#filters {
  pic-facets {
    display:flex;
    flex-wrap:wrap;
    width:100%;
  }
  pic-facet-select, pic-facet-slider {
    width:100%;
    clear:both;
  }
  header, h3 {
    width:100% !important;
  }
  @include breakpoint(medium) {
    pic-facets {
      display:inline-block;
    }
    pic-facet-select, pic-facet-slider {
      width:20%;
      clear:none;
      display:block;
      float:left;
      text-align:center;
    }
  }
}
.filters.genealogy .filter-box {
  .genealogy-page {
    &-next, &-prev {
      background-color: $color-blue;
      &:hover {
        background-color: rgba($color-blue, 0.8);
      }
    }
  }
}
//Error - - has to be corrected
.ng-valid-email .errors {
    margin-bottom: .375rem;
    padding: .25rem .3125rem;
    border-radius: 3px;
    background: rgba(201,34,54,0.15);
    color: #c92236;
    border: 1px solid #c92236;
    text-align: center;
    line-height: 1.25rem;
}

//icons colors
.tablerecord.active, .marriage-record, .birth-record, .registration-record {
    ol li .record-searchplus i, li.male i, li.female i {
        color: $color-blue;
    }
    .groom-bride, .born {
        .male i, .female i {
            color: $color-blue;
        }
    }
    ol li .record-searchplus:hover {
        background-color: $color-blue;
    }
}


