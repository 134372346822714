.footer {
    background: $color-paragraph;
    clear:both;
    font-size:rem-calc(16);    
    @include breakpoint(small) {
        height: 280px !important;
    }
    @include breakpoint(medium) {
        height: 100px !important;
        padding: 3px 0 82px 10px;
    }

    .close-button, .close-button.medium {
        right: 1rem;
        top: 0.5rem;
        font-size: 2em;
        line-height: 1;
    }
    .close-button {
        position: absolute;
        color: #8a8a8a;
        cursor: pointer;
    }
    .none-text-decoration a {
        text-decoration: none;
    }
    .brd-address {
        color: #fff;
        padding-top: 22px;
        margin-bottom:10px;

        @include breakpoint(small){
            //text-align: center;
            left: 0px;
        }
        @include breakpoint(medium){
            left: -40px;
        }
        a {
            color: $color-white;
            text-decoration: underline;
            padding-top: 10px;
        }
    }

    //Gemeente breda logo
    .gemeente-breda {
        display:block;
        margin:27px auto 0;
        width:188px;
        height:24px;
        background:url('../img/logo-breda.png') no-repeat; 
        text-indent:-9999px; 
    }

    //Social media icons for footer
    .footer-links {
        width:100%;
        color: #fff;
        position:relative;
        margin:4px 0 10px;
        .moduletable {
            position:absolute;
            right:184px;
            top:16px;
            z-index:0;
            box-shadow:none !important;
            margin:0 !important;
        }
        .menu {
            a {
                color:$color-white;
                text-decoration:underline;
                padding:0;
                &:hover {
                    text-decoration:none;
                }
            }
            .active a {
                background:none !important;
            }
        }
        li {
            display: inline-block;
            margin-left: 10px;
        }
        //Discailmer text as spam
        span {
            width: 233px;
            position: relative;
            left: -80px;
            top: 18px;
        }
    }
    .social {
        position:absolute;
        z-index:0;
        right:5px;
        top:22px;
        a {
            display:block;
            height: 40px;
            width: 41px; 
        }
        span {
            display: none;
        }
    }
    .fb-li, .youtube-li, .twitter-li {
        height: 40px;
        width: 41px; 
        background-repeat: no-repeat;
        &:hover {
            transition: background-image 0.2s;
            cursor:pointer;
        }

    }
    .fb-li {
        background-image: url(../img/social/fb.png);
        &:hover {
            background-image: url(../img/social/fb-h.png);
        }
    }
    .youtube-li {
        background-image: url(../img/social/yt.png);
        &:hover {
            background-image: url(../img/social/yt-h.png);
        }
    }
    .twitter-li {
        background-image: url(../img/social/fl.png);
        &:hover {
            background-image: url(../img/social/fl-h.png);
        }
    }
}
