html,
body {
    height: 100%;
}

%back-variables {
    background-size: 150px, 100px, 118px, cover;
    background-repeat: no-repeat;
    background-position: 0% 8%, 100% 20%, 41% 100%;
}

iframe {
    border: 0;
}

a.skiplink {
    background-color: #D8E9F6;
    border: 2px solid #12405C;
    display: block;
    font-size: 1.3em;
    left: -1000em;
    padding: 0.8em;
    position: absolute;
    z-index: 8000;
}

a.skiplink:focus,
a.skiplink:active {
    top: 1em;
    left: 1em;
    z-index: 8000;
    color: #1E6891;
}

body {
    font-family: 'Lato', sans-serif;
    background: url(../svg/slefttop.svg) no-repeat, url(../svg/sright.svg) no-repeat, url(../svg/sbottom.svg) no-repeat, url(../img/bg.jpg) no-repeat;
    background-attachment: fixed !important;
    @extend %back-variables;
    @media (min-width: 0px) and (max-width: 800px) {
        background-size: 30%, 30%, 30%, cover;
        background-position: 0% 80px, 100% 250px, 41% 100%;
    }
    @media only screen and (min-width:801px) {
        background-size: 307px, 313px, 407px, cover;
        background-position: 0% 130px, 100% 338px, 41% 100%;
    }
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;
    /* align items in Main Axis */
    align-items: stretch;
    /* align items in Cross Axis */
    align-content: stretch;
    /* Extra space in Cross Axis */
    //Add blue colore to input field
    input::-webkit-input-placeholder {
        color: $color-blue;
    }
    li {
        list-style-type: none;
    }
    .main-content {
        background: rgba(0, 110, 182, 0.25);
        padding: 155px 0 0;
        flex: 1 0 auto;
        @include breakpoint(small) {
            padding: 100px 0 0;
        }
        @include breakpoint(medium) {
            padding: 155px 0 0px;
            z-index: 1;
        }
    }
    .footer {
        flex-shrink: 0;
    }
    div[itemprop=articleBody] {}
    img {
        &.pull-left {
            float: left;
        }
        &.pull-right {
            float: right;
        }
        &.pull-center {
            text-align: center;
        }
    }
    //images-align
    figure {
        display: inline-block;
        width: auto;
        img {
            margin: 0 !important;
            width: 100%;
        }
        figcaption {
            width: inherit;
            max-width: inherit;
            clear: both;
            display: block;
            background: $color-gray;
            font-style: italic;
            font-size: rem-calc(16);
            padding: 6px 8px;
            max-width: 100%;
            &.pull-left {
                text-align: left;
            }
            &.pull-right {
                text-align: right;
            }
            &.pull-center {
                text-align: center;
            }
            &.w100 {
                max-width: 100px;
            }
            &.w200 {
                max-width: 200px;
            }
            &.w300 {
                max-width: 300px;
            }
            &.w400 {
                max-width: 400px;
            }
            &.w500 {
                max-width: 500px;
            }
            &.w600 {
                max-width: 600px;
            }
            &.w700 {
                max-width: 700px;
            }
            &.w800 {
                max-width: 800px;
            }
            &.w900 {
                max-width: 900px;
            }
            &.w1000 {
                max-width: 1000px;
            }
        }
    }
    figure.pull-left {
        float: left;
        margin: 16px 16px 8px 0;
        figcaption {}
    }
    figure.pull-right {
        float: right;
        margin: 16px 0 8px 16px;
    }
    figure.pull-center {
        width: 100%;
        float: none;
        text-align: center;
        margin: 5px auto 16px;
        img,
        figcaption {
            margin: 0 auto;
            max-width: 100%;
        }
    }
    .lnm {
        padding-left: 0 !important;
    }
    .rnm {
        @include breakpoint(medium) {
            padding-right: 0 !important;
        }
    }
}

body.collectie {
    background: url(../svg/slefttop.svg) no-repeat, url(../svg/sright.svg) no-repeat, url(../svg/sbottom.svg) no-repeat, url(../img/bg-collectie.jpg) no-repeat;
    background-attachment: fixed !important;
    @extend %back-variables;
    @media (min-width: 0px) and (max-width: 800px) {
        background-size: 30%, 30%, 30%, cover;
        background-position: 0% 80px, 100% 250px, 41% 100%;
    }
    @media (min-width: 801px) {
        background-size: 307px, 313px, 407px, cover;
        background-position: 0% 130px, 100% 338px, 41% 100%;
    }
    .article-header h4 {
        color: $color-blue;
        font-weight: bold;
    }
    .back-button {
        display: none;
    }
}

body.informatie {
    background: url(../svg/slefttop.svg) no-repeat, url(../svg/sright.svg) no-repeat, url(../svg/sbottom.svg) no-repeat, url(../img/bg-informatie.jpg) no-repeat;
    background-attachment: fixed !important;
    @extend %back-variables;
    @media (min-width: 0px) and (max-width: 800px) {
        background-size: 30%, 30%, 30%, cover;
        background-position: 0% 80px, 100% 250px, 41% 100%;
    }
    @media (min-width: 801px) {
        background-size: 307px, 313px, 407px, cover;
        background-position: 0% 130px, 100% 338px, 41% 100%;
    }
}

body.verdieping {
    background: url(../svg/slefttop.svg) no-repeat, url(../svg/sright.svg) no-repeat, url(../svg/sbottom.svg) no-repeat, url(../img/bg-verdieping.jpg) no-repeat;
    @extend %back-variables;
    @media (min-width: 0px) and (max-width: 800px) {
        background-size: 30%, 30%, 30%, cover;
        background-position: 0% 80px, 100% 250px, 41% 100%;
    }
    @media (min-width: 801px) {
        background-size: 307px, 313px, 407px, cover;
        background-position: 0% 130px, 100% 338px, 41% 100%;
    }
}

.mobile-not {
    display: none;
}

//RESETTER VOOR TOP BLOCKS
.no-space-bottom {
	margin-bottom: inherit !important;

	@include breakpoint(medium) {
		margin-bottom: -22px !important;
	}
}

.cc-message {
	a,
	.cc-message-text {
		padding: 0;
		margin: 0;
		line-height: 1.3;
		font-family: 'Lato', sans-serif !important;
	}
}

.cc-window .cc-compliance .cc-btn.cc-allow {
	min-width: auto;
}
