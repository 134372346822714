.brd-newsletter {
    label {
        display: none;
    }
    .custom-input-width {
        border: none;
        font-size: rem-calc(20);
        &::-moz-placeholder {
            color: $color-blue;
            opacity: 1;
            font-size: rem-calc(20);
        }
    }
}

#mc_embed_signup {
    position: relative;
    div.inschrijven-succes {
        position: absolute;
        top: 0;
        left: 0;
        background: $color-blue;
        height: 216px;
        width: 100%;
        font-size: rem-calc(24);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

table {
    @include breakpoint(small only) {
        width: 100% !important;
    }
}