//SEARCHBOX mediabank
.mediabank-search {
    .mediabank-search-fieldset {
        padding-right: 0px;
    }
    .mediabank-search-term {
        width: 100% !important;
    }
    .mediabank-search-submit {
        margin-right:0 !important;
    }
}

//Boeken, tijdschriften en krantenartikelen
.mediabank-library {
    .mediabank-result-table .mediabank-result-list {
        thead {
            background: $color-blue;
            tr th {
                background: $color-blue;
                color:$color-white !important;
            }
            th:nth-child(3) {  
                width:160px;
            }
        }
        tr.even, tr.alt, tr:nth-of-type(2n) {
            background: rgba($color-blue ,0.1)
        }
        tr td {
            padding:12px 1.25rem;
        }
        tr th, tr td {
            font-size: rem-calc(16);
        }
        tr:hover {
            background: rgba($color-blue ,0.2);
        }
        tr.mediabank-result-row:visited, 
        tr.mediabank-result-row.focused {
            background: $color-blue;
            a, span {
                color:$color-white !important;                
            }         
        }
    }
    .metadata-content-hierarchy {
        margin-top:6px;
    }
    .mediabank-focused-left {
        display:none;
    }
    .mediabank-focused-right {
        width:100%;
    }
    .mediabank-gallery-focused {
        background: transparent;
        margin-top:0;
        .mediabank-gallery-focused-info {
            color:$color-text;
            h3 {
                color:$color-text;                
            }
        }
    }
    .mediabank-detail-button {
        display: none;
    }
    .mediabank-result-header {
        color:$color-text;
        margin-bottom: -20px !important;
    }
    .pagination-jump {
        display: inline-block;
        border:1px solid $color-blue;
        text-align:center;
    }
    
    @media (max-width: 640px) {
        .mediabank-search .mediabank-search-submit {
            margin-right: 0;
        }
        .mediabank-filter-list {
            > li {
                margin-bottom:0;
            }
        }
        .pagination-form, .mediabank-pagination span {
            display:none;
        }
        .mediabank-pagination {
            color:#fff;
        }
        //TABLE
        tr {
            display:flex;
            flex-wrap: wrap;
            width:95vw;
            td {
                flex-grow:1;
            }
        }        
    }

}

