#picturae-webexpo-container * {
    font-family: 'Lato', sans-serif;
}

@include breakpoint(medium down) {
    body.pic-webexpo-detail-view {
        .main-content {
            z-index: 100;
            padding: 0;
            .row {
                max-width: 100%;
                .small-12,
                .medium-12 {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
        .pic-expo {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
        }
    }
}

#picturae-webexpo-container {
    @include breakpoint(small only) {
        header.overview-header {
            display: none;
        }
    }
    .webexpo-topbar {
        background: #262626 !important;
        width: 100%;
    }
    .webexpo-scroll,
    .webexpo-bg-image {
        background-color: #fff !important;
    }
    .webexpo-bg-image {
        background-image: none !important;
    }
    .webexpo-intro .webexpo-title .webexpo-date::before,
    .webexpo-intro .webexpo-title .webexpo-date::after {
        background: $color-blue;
        box-shadow: none;
        height: 3px;
    }
    .webexpo-intro {
        h1 {
            color: $color-blue;
            font-size: rem-calc(60);
            &::after {
                background: $color-blue;
                box-shadow: none;
                height: 3px;
            }
        }
        .webexpo-title {
            text-shadow: none;
            .webexpo-date {
                color: $color-blue;
                font-size: rem-calc(20);
            }
        }
        .webexpo-subtitle {
            color: $color-text;
        }
    }
}

.webexpo-navigation-slider-lane {
    background: #999;
}

.webexpo-content-text {
    background: #fff !important;
    p {
        color: $color-text;
    }
    a {
        color: $color-blue;
        &:hover {
            color: $color-blue-hover;
        }
    }
    h1,
    h2 {
        color: $color-blue;
    }
    h3,
    h4,
    h5,
    h6 {
        color: $color-text;
    }
}

.webexpo-content-image {
    background: #f0f0f0 !important;
}

.webexpo-topbar-buttons .button-list {
    .button-help,
    .button-fullscreen,
    .button-return,
    .button-close {
        &:hover {
            background-color: $color-blue;
        }
    }
}

.webexpo-detail-metadata,
.webexpo-detail-buttons {
    .button-close:hover,
    .button-details:hover,
    .button-beeldbank:hover,
    .button-beeldbank:hover {
        background-color: $color-blue;
    }
}

.webexpo-expositions {
    .expositions-list {
        li {
            padding: 0;
        }
    }
}

.webexpositie {
    height: 300px;
    cursor: pointer;
    margin: 20px 0;
    display: block;
    position: relative;
    .brd-img {
        background-repeat: no-repeat;
        background-size: 100%;
        height: 300px;
    }
    h2 {
        @extend %block-basic; //see elements.scss
        background: $color-white;
        &::before {
            background: url('../svg/arrow-up.svg') no-repeat;
        }
    }
    &:hover {
        h2 {
            //color: $color-black;
            cursor: pointer;
        }
    }
    .picturae-webexpo {
        background-color: $color-white;
        padding-top: 10px;
        font-family: 'Lato', sans-serif;
    }
    .blue-hover {
        position: absolute;
        height: 300px;
        width: 100%;
        top: 0;
        /* &:hover {
            background: rgba($color-blue, 0.2);
        } */
    }
}

.expositions-list {
    .animated {
        background: #fff;
    }
}

.webexpo-overview {
    max-width: none;
    padding: 0;
    .overview-header {
        background-color: #fff;
        box-shadow: 0 3px 3px rgba(0, 0, 0, 0.3);
        color: $color-blue;
        display: block;
        padding: 8px 20px 12px;
        width: 100%;
        margin-top: 0;
        height: 64px;
        h1 {
            font-size: 2rem;
            padding: 3px 0px 12px;
            margin: 3px 0 0;
        }
    }
    .webexpo-overview-item {
        h2,
        h3,
        p {
            padding: 0 8px 0 16px;
        }
        h2 {
            text-transform: none;
            margin: 6px 0;
        }
        h3 {
            font-size: rem-calc(18);
        }
        p,
        h3 {
            display: none;
        }
        .date {
            background: rgba(0, 110, 182, 0.85);
            text-shadow: none;
            font-size: rem-calc(16);
        }
        .button-view {
            color: #fff;
            background: $color-blue;
            border-radius: 0;
            padding: 2px 8px;
            border: 1px solid $color-blue;
            &:hover {
                color: $color-blue;
                background: #fff;
                border: 1px solid $color-blue;
                //background: $color-light-gray;
            }
        }
        .image-link:hover {
            /* &:before {
                width:100%;
                height:200px;
                background:rgba($color-blue, 0.2);
                content:'';
                left:0;
                //z-index:800;
                position:absolute;
            } */
        }
    }
    .expositions-list li {
        @include breakpoint(medium) {
            width: 48%;
            margin-right: 2%;
            &:nth-child(2n) {
                margin-right: 0;
            }
            &:nth-child(3n) {
                margin-right: 2%;
            }
        }
        @include breakpoint(large) {
            width: 32%;
            margin-right: 1.5%;
            &:nth-child(2n) {
                margin-right: 1.5%;
            }
            &:nth-child(3n) {
                margin-right: 0;
            }
        }
        margin-bottom:20px;
        box-shadow: $blocks-shadow;
    }
    .button-fullscreen {
        background-color: $color-blue;
        border-radius: 0;
        height: 32px;
        padding: 4px 16px;
        top: 7px;
        right: 9px;
        &:hover {
            background-color: rgba($color-blue, 0.7);
            color: #000;
        }
        @include breakpoint(small) {
            display: none;
        }
        @include breakpoint(medium) {
            display: block;
        }
    }
}

.webexpo-sort {
    right: 52px;
    top: 7px;
    @include breakpoint(small) {
        display: none;
    }
    @include breakpoint(medium) {
        display: block;
    }
    .button-sort {
        background: $color-blue;
        border-radius: 0;
        padding: 4px 16px;
        height: 32px;
        &:hover {
            background: $color-light-gray;
            color: $color-black;
        }
        span {
            display: block;
        }
    }
    ul {
        background: $color-light-gray;
    }
    &:hover ul a {
        background: $color-light-gray;
        color: $color-black !important;
    }
}

.hide-title h3 {
    display: none;
}

//WEBEXPO
.webexpo-topbar .webexpo-topbar-title h2 {
    text-transform: none;
    font-weight: 400;
    color: #262626;
    text-shadow: none;
}

.webexpo-scroll {
    h1.webexpo-name {
        text-transform: none;
        font-size: rem-calc(48);
    }
}