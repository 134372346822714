// apply a natural box layout model to all elements, but allowing components to change
html {
    box-sizing: border-box;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    
}

*,
*:before,
*:after {
    box-sizing: inherit;
}



body {
    min-height: 100%;
    height: 100%;
    padding:0;
    margin: 0;
    background: #fff;
    font-weight: 400;
    color: $color-text;
    font-size:rem-calc(18);
    line-height: 1.5;
    position: relative;
}

// Reset
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
    margin:0;
    padding:0;
}


%block-basic {
    font-size: rem-calc(24);
    line-height: rem-calc(28);
    font-weight: 700;
    height: auto;
    width: 100%;
    padding: 16px 20px 20px;
    position: absolute;
    bottom: 0;
    &::before {
        width:56px;
        height:28px;
        content:'';
        vertical-align: middle;
        margin:0 auto;
        padding: 0;
        position: absolute;
        top:-17px;
        left:0;
        right:0;
    }
}